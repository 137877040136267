import React, { useEffect, useMemo, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import {
    Cluster,
    ClusterStats,
    MarkerClusterer as GoogleMarkerClusterer,
    Marker,
    Renderer,
} from "@googlemaps/markerclusterer";
import { ClusteredMarkerChip } from "./markerChip";
import { MapPointProps } from "./map";
interface MarkerClustererProps {
    points: MapPointProps[];
    onPointSelect?: (id: string) => void;
    onMouseEnter?: (el: HTMLElement, ids: string[]) => void;
    onMouseLeave?: (el: HTMLElement, ids: string[]) => void;
}

const markerClass = "clustered-marker";

export const MarkerClusterer = ({
    points,
    onPointSelect,
    onMouseEnter,
    onMouseLeave,
}: MarkerClustererProps) => {
    const map = useMap();
    const [clusterer, setClusterer] = useState<GoogleMarkerClusterer>();

    // Initialize MarkerClusterer, if the map has changed
    useEffect(() => {
        if (!map) return;
        if (!clusterer) {
            setClusterer(
                new GoogleMarkerClusterer({
                    map,
                    renderer:
                        !!onMouseEnter || !!onMouseLeave
                            ? new MarkerClusterRenderer({
                                  onMouseEnter,
                                  onMouseLeave,
                                  markerClass,
                              })
                            : undefined,
                })
            );
        }
    }, [map]);

    return points?.map((r, i) => (
        <ClusteredMarkerChip
            key={`${i}-${r.coords.lat}-${r.coords.lng}`}
            clusterer={clusterer}
            point={r}
            onSelect={onPointSelect}
            className={markerClass}
        />
    ));
};

// Based on DefaultRenderer from "@googlemaps/markerclusterer"
class MarkerClusterRenderer implements Renderer {
    private _onMouseEnter: (marker: Node, ids: string[]) => void;
    private _onMouseLeave: (marker: Node, ids: string[]) => void;
    private _markerClass: string;

    constructor({
        onMouseEnter,
        onMouseLeave,
        markerClass,
    }: {
        onMouseEnter: (marker: Node, ids: string[]) => void;
        onMouseLeave: (marker: Node, ids: string[]) => void;
        markerClass: string;
    }) {
        this._onMouseEnter = onMouseEnter;
        this._onMouseLeave = onMouseLeave;
        this._markerClass = markerClass;
    }

    private getMarkerIds(markers: Marker[]) {
        return markers
            .map(
                (m) =>
                    (
                        (
                            (m as google.maps.marker.AdvancedMarkerElement)
                                .content as Element
                        )?.querySelector(`.${this._markerClass}`) as HTMLElement
                    )?.dataset.id
            )
            .filter(Boolean);
    }

    public render(
        { count, position, markers }: Cluster,
        _: ClusterStats,
        map: google.maps.Map
    ): Marker {
        // change color if this cluster has more markers than the mean cluster
        const color = "#fff";

        // create svg literal with fill color
        const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
    <text x="50%" y="50%" style="fill:#000" text-anchor="middle" font-size="65" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
    </svg>`;

        // adjust zIndex to be above other markers
        const zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;

        // create cluster SVG element
        const parser = new DOMParser();
        const svgEl = parser.parseFromString(
            svg,
            "image/svg+xml"
        ).documentElement;
        svgEl.setAttribute("transform", "translate(0 25)");

        const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions =
            {
                map,
                position,
                zIndex,
                content: svgEl,
            };

        const marker = new google.maps.marker.AdvancedMarkerElement(
            clusterOptions
        );

        if (this._onMouseEnter) {
            marker.content?.getRootNode().addEventListener("mouseenter", () => {
                this._onMouseEnter(marker.content, this.getMarkerIds(markers));
            });
        }

        if (this._onMouseLeave) {
            marker.content
                ?.getRootNode()
                .addEventListener("mouseleave", () =>
                    this._onMouseLeave(
                        marker.content,
                        this.getMarkerIds(markers)
                    )
                );
        }

        return marker;
    }
}
