import React, { useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { Map as GoogleMap } from "@vis.gl/react-google-maps";
import { useFitMap } from "../../../hooks/map/useFitMap";
import { Polyline } from "./polyline";
import { MarkerChip } from "./markerChip";
import { MarkerClusterer } from "./markerClusterer";
import { useHoverMenuProps } from "../../../hooks/useHoverMenuProps";
import { MuiHoverMenu } from "../muiHoverMenu";
import CircleIcon from "@mui/icons-material/Circle";
import { IMuiMenuItemProps } from "../muiMenu";

export interface MapRouteProps {
    coords: Position[];
    color?: string;
    highlight?: boolean;
}

interface Position {
    lat: number;
    lng: number;
}

export interface MapPointProps {
    id: string;
    coords: Position;
    text?: string | JSX.Element | JSX.Element[];
    fullText?: string | JSX.Element | JSX.Element[];
    icon?: JSX.Element;
    textColorClass?: string;
    textBgColorClass?: string;
}

interface MapProps {
    routes?: MapRouteProps[];
    points?: MapPointProps[];
    minHeight?: string;
    autoZoom?: boolean;
    zoomKey?: string | number;
    groupPoints?: boolean;
    pointSelectionEnabled?: boolean;
    onPointSelect?: (id: string) => void;
    hoverMenuEnabled?: boolean;
}

export const Map = ({
    routes,
    points,
    minHeight = "200px",
    autoZoom = true,
    groupPoints = true,
    pointSelectionEnabled = false,
    onPointSelect,
    zoomKey,
    hoverMenuEnabled = false,
}: MapProps) => {
    const [hasZoomed, fitMap] = useFitMap(routes, points, autoZoom);
    const menuProps = useHoverMenuProps();

    useEffect(() => {
        if (hasZoomed && !!zoomKey) {
            fitMap();
        }
    }, [zoomKey]);

    const menuItems = useMemo(
        () =>
            hoverMenuEnabled
                ? points
                      ?.filter((p) => menuProps?.ids?.includes(p.id))
                      .map(
                          (p) =>
                              ({
                                  label: p.fullText ?? p.text,
                                  icon: (
                                      <CircleIcon
                                          className={`text-${p.textBgColorClass}`}
                                      />
                                  ),
                                  onClick: () => onPointSelect(p.id),
                              } as IMuiMenuItemProps)
                      )
                : null,
        [menuProps?.ids, points, hoverMenuEnabled]
    );

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                minHeight={minHeight}
                height="100%"
            >
                <GoogleMap
                    defaultCenter={{ lat: 0, lng: 0 }}
                    defaultZoom={10}
                    mapId="DEFAULT_MAP"
                    className="google-map"
                    reuseMaps
                >
                    {routes?.map((r, i) => (
                        <Polyline
                            key={`${i}-${r.coords
                                .map((c) => `${c.lat}-${c.lng}`)
                                .join(",")}-${(
                                r.highlight ?? false
                            ).toString()}`}
                            route={r}
                        />
                    ))}
                    {groupPoints ? (
                        <MarkerClusterer
                            points={points}
                            onPointSelect={
                                pointSelectionEnabled ? onPointSelect : null
                            }
                            onMouseEnter={
                                hoverMenuEnabled
                                    ? (anchorEl, ids) => {
                                          menuProps.setElementHovered(true);
                                          menuProps.setMenu({ anchorEl, ids });
                                      }
                                    : null
                            }
                            onMouseLeave={
                                hoverMenuEnabled
                                    ? () => menuProps.setElementHovered(false)
                                    : null
                            }
                        />
                    ) : (
                        points?.map((r, i) => (
                            <MarkerChip
                                key={`${i}-${r.coords.lat}-${r.coords.lng}`}
                                point={r}
                                onSelect={
                                    pointSelectionEnabled ? onPointSelect : null
                                }
                            />
                        ))
                    )}
                </GoogleMap>
            </Box>
            {hoverMenuEnabled ? (
                <MuiHoverMenu {...menuProps} items={menuItems} />
            ) : null}
        </>
    );
};
