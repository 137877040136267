import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { IMuiMenuItemProps } from "./muiMenu";

interface MonitorViewMapHoverMenuProps {
    anchorEl: null | HTMLElement;
    ids: string[] | null;
    setMenuHovered: (val: boolean) => void;
    onClose: () => void;
    items: IMuiMenuItemProps[];
    keepOpenAfterSelect?: boolean;
}

export const MuiHoverMenu = ({
    anchorEl,
    setMenuHovered,
    onClose,
    items,
    keepOpenAfterSelect,
}: MonitorViewMapHoverMenuProps) => {
    const handleItemClick = (item: IMuiMenuItemProps) => {
        if (!keepOpenAfterSelect) {
            onClose();
        }
        if (item.onClick) {
            item.onClick();
        }
    };

    return (
        <>
            {!!anchorEl && items?.length > 0 ? (
                <Popper
                    open
                    anchorEl={anchorEl}
                    role={undefined}
                    onMouseEnter={() => setMenuHovered(true)}
                    onMouseLeave={() => setMenuHovered(false)}
                    modifiers={[
                        {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                                altAxis: true,
                                altBoundary: true,
                                tether: true,
                                rootBoundary: "viewport",
                                padding: 8,
                            },
                        },
                    ]}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList>
                                {items.map((i, idx) => (
                                    <MenuItem
                                        key={`${idx}-${i.label}`}
                                        onClick={() => handleItemClick(i)}
                                    >
                                        {i.icon ? (
                                            <ListItemIcon>
                                                {i.icon}
                                            </ListItemIcon>
                                        ) : null}
                                        <ListItemText>{i.label}</ListItemText>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            ) : null}
        </>
    );
};
