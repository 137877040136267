import React, { useMemo } from "react";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { formatTime } from "../workTimeBeta/workTimeDetailsList";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { Base } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { SectionHeader } from "../layout/sectionHeader";

interface MonitorViewSidebarProps {
    workShift: WorkShiftDto;
    onClose: () => void;
    selectedWorkShiftTimeSlotId: string;
    setSelectedWorkShiftTimeSlotId: (id: string) => void;
    setHoveredWorkShiftTimeSlotId: (id: string) => void;
}

export const MonitorViewSidebar = ({
    workShift,
    onClose,
    selectedWorkShiftTimeSlotId,
    setSelectedWorkShiftTimeSlotId,
    setHoveredWorkShiftTimeSlotId,
}: MonitorViewSidebarProps) => {
    const { workShiftTimeSlots, effectiveDate } = workShift;

    const headerLabel = useMemo(
        () =>
            `${workShiftTimeSlots[0]?.employeeName} ${Base.dayjsToDateStr(
                effectiveDate
            )}`,
        [workShiftTimeSlots, effectiveDate]
    );

    return (
        <Box minWidth={350} height="100%" display="flex" flexDirection="column">
            <SectionHeader position="sticky">
                <Typography
                    component="div"
                    sx={{ flexGrow: 1 }}
                    className="section-header text-truncate"
                >
                    {headerLabel}
                </Typography>
                <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </SectionHeader>
            <Box
                sx={{
                    overflowY: "auto",
                }}
            >
                <List dense disablePadding>
                    {workShiftTimeSlots
                        ?.sort((a, b) =>
                            Base.dayjsCompare(a.startDate, b.startDate)
                        )
                        .map((s) => (
                            <WorkTimeListItem
                                key={s.id}
                                slot={s}
                                isSelected={
                                    s.id === selectedWorkShiftTimeSlotId
                                }
                                onSelect={setSelectedWorkShiftTimeSlotId}
                                onHover={setHoveredWorkShiftTimeSlotId}
                            />
                        ))}
                </List>
            </Box>
        </Box>
    );
};

interface WorkTimeListItemProps {
    slot: WorkShiftTimeSlotItem;
    isSelected: boolean;
    onSelect: (id: string) => void;
    onHover: (id: string) => void;
}

const WorkTimeListItem = ({
    slot,
    isSelected,
    onSelect,
    onHover,
}: WorkTimeListItemProps) => {
    const { id, startDate, endDate, workTimeTypeName, workTimeTypeType } = slot;

    const timeLabel = useMemo(
        () =>
            `${formatTime(startDate, Base.dayjsToJsonDate())} - ${
                endDate ? formatTime(endDate, Base.dayjsToJsonDate()) : ""
            }`,
        [startDate, endDate]
    );

    return (
        <ListItem
            disablePadding
            secondaryAction={new WorkShiftTimeSlotItem(slot).getDurationStr()}
        >
            <ListItemButton
                selected={isSelected}
                onClick={() => onSelect(id)}
                onMouseEnter={() => onHover(id)}
                onMouseLeave={() => onHover(null)}
            >
                <ListItemIcon>
                    <CircleIcon
                        className={`text-${getWorkTimeTypeColorClass(
                            workTimeTypeType
                        )}`}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={workTimeTypeName ?? "Ei työvaihetta"}
                    secondary={timeLabel}
                    sx={{ mr: 2 }}
                ></ListItemText>
            </ListItemButton>
        </ListItem>
    );
};
