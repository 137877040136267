import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, ListItemIcon, ListItemText } from "@mui/material";

export interface IMuiMenuItemProps {
    label?: string | JSX.Element;
    icon?: any;
    onClick?: Function;
    divider?: boolean;
}

interface IMuiMenuProps {
    label: any;
    items: IMuiMenuItemProps[];
    keepOpenAfterSelect?: boolean;
}

export default function MuiMenu({ label, items, keepOpenAfterSelect }: IMuiMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (item: IMuiMenuItemProps) => {
        if(!keepOpenAfterSelect){
            handleClose();
        }
        if (item.onClick) {
            item.onClick();
        }
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                {label}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {items.filter(Boolean).map((i, idx) => i.divider ? <Divider key={`div-${idx}`} /> : (
                    <MenuItem key={`${idx}-${i.label}`} onClick={() => handleItemClick(i)}>
                        {i.icon ? <ListItemIcon>{i.icon}</ListItemIcon> : null}
                        <ListItemText>
                            {i.label}
                        </ListItemText>
                    </MenuItem>)
                )}
            </Menu>
        </div>
    );
}
