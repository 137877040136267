import { useEffect, useState } from "react";

interface Menu {
    anchorEl: null | HTMLElement;
    ids: string[] | null;
}

export const useHoverMenuProps = () => {
    const [menu, setMenu] = useState<Menu>();
    const [elementHovered, setElementHovered] = useState(false);
    const [menuHovered, setMenuHovered] = useState(false);
    const { anchorEl, ids } = menu ?? {};

    const onClose = () => {
        setMenu(null);
        setMenuHovered(false);
        setElementHovered(false);
    };

    useEffect(() => {
        // Close menu when mouse leaves element or menu and menu exists
        if (!elementHovered && !menuHovered && !!menu) {
            onClose();
        }
    }, [elementHovered, menuHovered]);

    return {
        anchorEl,
        ids,
        setMenu,
        setElementHovered,
        setMenuHovered,
        onClose,
    };
};
