import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MainLayout } from "../layout/mainLayout";
import {
    getVehiclePositions,
    getVehicles,
} from "../../services/vehicleService";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { useFetch } from "../../hooks/useFetch";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { useInterval } from "usehooks-ts";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { MonitorViewMap } from "./monitorViewMap";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { getCurrentWorkShift } from "../../services/workShiftService";
import { handleApiError } from "../../models/store/storeEffects";
import { useAppDispatch } from "../../framework/customStore";
import { useReload } from "../../hooks";
import { MonitorViewSidebar } from "./monitorViewSidebar";
import { MonitorViewFilters } from "./monitorViewFilters";

const refreshInterval = 60 * 1000; // 1 minute

export const MonitorViewMain = () => {
    const [positionsLoading, setPositionsLoading] = useState(true);
    const [workShiftLoading, setWorkShiftLoading] = useState(false);
    const [vehicles, setVehicles] = useState<VehicleListItemDto[]>();
    const [positions, setPositions] = useState<VehiclePositionInfo[]>();
    const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
    const [selectedWorkShiftTimeSlotId, setSelectedWorkShiftTimeSlotId] =
        useState<string>();
    const [hoveredWorkShiftTimeSlotId, setHoveredWorkShiftTimeSlotId] =
        useState<string>();
    const [workShift, setWorkShift] = useState<WorkShiftDto>();
    const [key, reload] = useReload();

    const dispatch = useAppDispatch();
    const loading = positionsLoading || workShiftLoading;

    const selectedEmployeeId = useMemo(
        () =>
            selectedVehicleId
                ? positions?.find((p) => p.vehicleId === selectedVehicleId)
                      ?.employeeWorkTimeTypes[0]?.employeeId
                : null,
        [positions, selectedVehicleId]
    );

    const onVehicleSelect = (id: string) => {
        setWorkShift(null);
        setSelectedWorkShiftTimeSlotId(null);

        if (id !== selectedVehicleId) {
            setSelectedVehicleId(id);
        } else {
            setSelectedVehicleId(null);
        }
    };

    const onWorkShiftTimeSlotSelect = (id: string) => {
        if (id !== selectedWorkShiftTimeSlotId) {
            setSelectedWorkShiftTimeSlotId(id);
        } else {
            setSelectedWorkShiftTimeSlotId(null);
        }
    };

    useFetch(
        (signal) => getVehicles(false, signal),
        {
            onSuccess: (res) => setVehicles(res.data),
            withLoadingIndicator: false,
        },
        []
    );

    useEffect(() => {
        if (!!positions) setPositionsLoading(false);
    }, [positions]);

    useEffect(() => {
        if (!!workShift) setWorkShiftLoading(false);
    }, [workShift]);

    const fetchLocations = useCallback(
        (showLoadingIndicator = false) => {
            if (!selectedVehicleId && vehicles?.length > 0) {
                if (showLoadingIndicator) {
                    setPositionsLoading(true);
                }

                getVehiclePositions(
                    selectedVehicleId
                        ? [selectedVehicleId]
                        : vehicles.map((v) => v.id),
                    true
                )
                    .then((res) => {
                        setPositions(res?.data);
                    })
                    .catch((e) => {
                        if (showLoadingIndicator) {
                            setPositionsLoading(false);
                        }
                        handleApiError(e, dispatch);
                    });
            }
        },
        [vehicles, selectedVehicleId]
    );

    const fetchWorkShift = useCallback(
        (showLoadingIndicator = false) => {
            if (!!selectedEmployeeId) {
                if (showLoadingIndicator) {
                    setWorkShiftLoading(true);
                }

                getCurrentWorkShift(selectedEmployeeId, true)
                    .then((res) => setWorkShift(res))
                    .catch((e) => {
                        if (showLoadingIndicator) {
                            setWorkShiftLoading(false);
                        }
                        handleApiError(e, dispatch);
                    });
            }
        },
        [selectedEmployeeId]
    );

    const onRefresh = (showLoadingIndicator = false) => {
        fetchLocations(showLoadingIndicator);
        fetchWorkShift(showLoadingIndicator);
        reload();
    };

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    useEffect(() => {
        fetchWorkShift();
    }, [fetchWorkShift]);

    useInterval(onRefresh, refreshInterval);

    return (
        <MainLayout
            noPadding
            topComponent={<MonitorViewFilters />}
            sideComponent={
                !!workShift ? (
                    <MonitorViewSidebar
                        workShift={workShift}
                        onClose={() => onVehicleSelect(null)}
                        selectedWorkShiftTimeSlotId={
                            selectedWorkShiftTimeSlotId
                        }
                        setSelectedWorkShiftTimeSlotId={
                            onWorkShiftTimeSlotSelect
                        }
                        setHoveredWorkShiftTimeSlotId={(id) =>
                            setHoveredWorkShiftTimeSlotId(id)
                        }
                    />
                ) : null
            }
            sideOverFlowHidden
        >
            <>
                <LoadingIndicator loading={loading} />
                <MonitorViewMap
                    loading={positionsLoading}
                    positions={positions}
                    onRefresh={() => onRefresh(true)}
                    selectedVehicleId={selectedVehicleId}
                    setSelectedVehicle={onVehicleSelect}
                    workShift={workShift}
                    reloadKey={key}
                    selectedWorkShiftTimeSlotId={selectedWorkShiftTimeSlotId}
                    hoveredWorkShiftTimeSlotId={hoveredWorkShiftTimeSlotId}
                    setSelectedWorkShiftTimeSlotId={onWorkShiftTimeSlotSelect}
                />
            </>
        </MainLayout>
    );
};
