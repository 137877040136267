import * as store from "../../framework/customStore";
import { ITypedOption, TypedOption } from "../common/typedOption";
import {
    ownerParameterRequireWorkOrderHourBookings,
    PrivacyPolicyUrl,
    ownerParameterSetWorkOrderDurationRoundingMinutes,
    ownerParameterWorkTimeAccountTransactionDepositSalaryRowTypeCode,
    ownerParameterWorkTimeAccountTransactionWithdrawSalaryRowTypeCode,
    ownerParameterWorkTimeAccountInUse,
    optionOwnerHasStorageEnabled,
    optionOwnerHasReportingEnabled,
    ownerParameterWorkOrderOpenDialogAfterDragAndDrop,
    optionWorkShiftRoundingMinutes,
    optionWorkShiftSplitDurationMinutes,
    defaultOptionWorkShiftSplitDurationMinutes,
    defaultOptionWorkShiftRoundingMinutes,
    optionOwnerHasWorkShiftRoundingEnabled,
    optionOwnerHasTransportEnabled,
    optionOwnerHasTransportInvoicingEnabled,
    optionIsFunctionsAreInUse,
    optionTransportationInvoicingFixedPriceId,
    optionOwnerHasWorkOrdersEnabled,
    optionWorkTimeVehiclesEnabled,
    optionWorkTimeEmployeeVehiclesEnabled,
    optionWorkTimeApprovalModeByShift,
    optionFuusorReportingEnabled,
    optionMonitorViewEnabled
} from "../common/consts";

export interface IOwnerParameters {
    getRequireWorkOrderHourBookings(): boolean;
    getPrivacyPolicy(): string;
    getWorkOrderOpenDialogAfterDragAndDrop(): boolean;
}

export class OwnerParameters implements IOwnerParameters {
    [x: string]: any;
    getParameters(): ITypedOption[] {
        const appState = store.customStore.getState();
        if (!appState || !appState.owner || !appState.owner.parameters) return [];
        return appState.owner.parameters;
    }

    getRequireWorkOrderHourBookings(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), ownerParameterRequireWorkOrderHourBookings, false);
    }

    getPrivacyPolicy(): string {
        return TypedOption.getStrValue(this.getParameters(), PrivacyPolicyUrl);
    }

    getSetWorkOrderDurationRoundingMinutes(): number {
        return TypedOption.getIntValue(this.getParameters(), ownerParameterSetWorkOrderDurationRoundingMinutes, 0);
    }

    getWorkOrderOpenDialogAfterDragAndDrop(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), ownerParameterWorkOrderOpenDialogAfterDragAndDrop, true);
    }

    //Salary
    getWorkTimeAccountInUse(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), ownerParameterWorkTimeAccountInUse, false);
    }

    getWorkTimeAccountTransactionDepositSalaryRowTypeCode(): string {
        return TypedOption.getStrValue(this.getParameters(), ownerParameterWorkTimeAccountTransactionDepositSalaryRowTypeCode);
    }

    getWorkTimeAccountTransactionWithdrawSalaryRowTypeCode(): string {
        return TypedOption.getStrValue(this.getParameters(), ownerParameterWorkTimeAccountTransactionWithdrawSalaryRowTypeCode);
    }

    static requireWorkOrderHourBookings(): boolean {
        const ownerParameters = new OwnerParameters();
        return ownerParameters.getRequireWorkOrderHourBookings();
    }

    static workOrderDurationRoundingMinutes(): number {
        const ownerParameters = new OwnerParameters();
        return ownerParameters.getSetWorkOrderDurationRoundingMinutes();
    }

    static workOrderOpenDialogAfterDragAndDrop(): boolean {
        const ownerParameters = new OwnerParameters();
        return ownerParameters.getWorkOrderOpenDialogAfterDragAndDrop();
    }

    getOwnerHasStorageEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasStorageEnabled, false);
    }

    getOwnerHasReportingEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasReportingEnabled, true);
    }
    getOptionIsFunctionsAreInUse(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionIsFunctionsAreInUse, false);
    }

    getOwnerHasWorkShiftRoundingEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasWorkShiftRoundingEnabled, false);
    }
    getWorkShiftSplitDurationMinutes(): number {
        return TypedOption.getIntValue(this.getParameters(), optionWorkShiftSplitDurationMinutes, defaultOptionWorkShiftSplitDurationMinutes);
    }
    getWorkShiftRoundingMinutes(): number {
        return TypedOption.getIntValue(this.getParameters(), optionWorkShiftRoundingMinutes, defaultOptionWorkShiftRoundingMinutes);
    }

    getOwnerHasTransportEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasTransportEnabled, false);
    }

    getOwnerHasTransportInvoicingEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasTransportInvoicingEnabled, false);
    }

    getOtionTransportationInvoicingFixedPriceId(): string {
        return TypedOption.getStrValue(this.getParameters(), optionTransportationInvoicingFixedPriceId);
    }

    getOwnerHasWorkOrdersEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionOwnerHasWorkOrdersEnabled, false);
    }
    getOptionWorkTimeVehiclesEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionWorkTimeVehiclesEnabled, false);
    }

    getOptionWorkTimeEmployeeVehiclesEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionWorkTimeEmployeeVehiclesEnabled, false);
    }

    getOptionWorkTimeApprovalModeByShiftEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionWorkTimeApprovalModeByShift, false);
    }

    getOptionFuusorReportingEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionFuusorReportingEnabled, false);
    }

    getOptionMonitorViewEnabled(): boolean {
        return TypedOption.getBooleanValue(this.getParameters(), optionMonitorViewEnabled, false);
    }
}
