export class WorkTimeType {
    workTimeTypeId: string;
    name: string;
    type: number;

    constructor();
    constructor(obj: WorkTimeType);
    constructor(obj?: any) {
        this.workTimeTypeId = (obj && obj.workTimeTypeId) || "";
        this.name = (obj && obj.name) || "";
        this.type = (obj && obj.type) ?? null;
    }
}

export interface EmployeeWorkTimeType {
    employeeId: string;
    employeeName: string;
    workTimeTypeId?: string;
    workTimeTypeName?: string;
    workTimeTypeType?: number;
}

export const getWorkTimeTypeColor = (type: number) => {
    switch (type) {
        case null:
            return "gray";
        case 0:
            return "red";
        case 1:
            return "green";
        case 2:
            return "blue";
        case 3:
            return "orange";
        case 4:
            return "yellow";
        case 5:
            return "purple";
        default:
            return "gray";
    }
};

export const getWorkTimeTypeColorClass = (type: number) => {
    switch (type) {
        case null:
            return "gray-light";
        case 0:
            return "red-light";
        case 1:
            return "green-light";
        case 2:
            return "blue-light";
        case 3:
            return "orange";
        case 4:
            return "yellow";
        case 5:
            return "purple";
        default:
            return "gray-light";
    }
};

export const getWorkTimeTypeContrastColorClass = (type: number): string => {
    return ["gray-light", "yellow"].includes(getWorkTimeTypeColorClass(type))
        ? "dark"
        : "white";
};
