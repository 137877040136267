import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { useParams } from "react-router-dom";
import { MuiDataGrid } from "../framework/muiDataGrid";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import * as workTimeBetaService from "../../services/workTimeBetaService";
import * as baseService from "../../services/baseService";
import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import MuiMenu from "../framework/muiMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { WorkTimeAutomationsIndicator } from "./workTimeAutomationsIndicator";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    Undo as UndoIcon,
} from "@mui/icons-material";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { EnumHelper, WorkShiftTimeSlotState } from "../../models/common/enums";
import { useTheme } from "@mui/material";
import { LocationPoint } from "../../models/common/locationPoint";
import {
    LocationMapDialog,
    LocationIcon,
} from "../framework/locationMapDialog";
import { WorkTimeEventIndicator } from "./workTimeEventIndicator";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {
    deleteWorkShiftTimeSlot,
    setEditId,
    toggleSelectedId,
} from "../../store/workShiftTimeSlotSlice";
import { useTranslation } from "react-i18next";
import { AnyAction } from "redux";
import {
    HourBookingCategory,
    IWorkHoursItem,
    deletableCategories,
} from "../../models/workTime/workHoursItem";
import { AppUtils } from "../../models/common/appUtils";
import {
    DataGridPremiumProps,
    useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import { useSelectCalculatedWorkHoursByDate } from "../../hooks/workTime/useSelectWorkHours";
import { ISalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { setShownColumns, setHoveredId } from "../../store/workTimeDetailsSlice";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import ReactDOM from "react-dom";

interface ISelectedLocation {
    title: string;
    point: LocationPoint;
}

interface StartTimeColValue {
    startDate: string;
    endDate: string;
    hasOverlapping: boolean;
    label: string;
}

interface IWorkTimeDetailsListProps {
    reloadList: () => void;
    workShiftTimeSlotItems: WorkShiftTimeSlotItem[];
    salaryRowTypes: ISalaryRowTypeItem[];
}

export const formatTime = (time: string, date: string) =>
    Base.isSameDay(Base.dateStrToDayjsIgnoreTimezone(time), date)
        ? Base.dateStrToOriginalTimezoneTimeStr(time)
        : Base.dateStrToOriginalTimezoneDateTimeStr(time);

const locationIconColWidth = 50;
const startTimeColWidth = 155;

interface ActionCellProps {
    handleEdit: (id: string) => void;
    handleDelete: (id: string) => void;
    handleLock: (ids: string[], lock: boolean) => void;
    handleToggleDeleteWorkHour: (
        id: string,
        category: string,
        categoryName: string,
        restore: boolean
    ) => void;
}

function ActionCell(props: GridRenderCellParams & ActionCellProps) {
    const {
        value,
        row,
        rowNode,
        handleEdit,
        handleDelete,
        handleLock,
        handleToggleDeleteWorkHour,
    } = props;
    const apiRef = useGridApiContext();
    const theme = useTheme();
    const { t } = useTranslation();

    if (rowNode.depth > 0 && !deletableCategories().includes(row.category))
        return null;

    if (rowNode.depth > 0)
        return (
            <Button
                onClick={() =>
                    handleToggleDeleteWorkHour(
                        row.workShiftTimeSlotId,
                        row.category,
                        row.categoryName,
                        !!row.deleted
                    )
                }
            >
                {row.deleted ? <UndoIcon /> : <DeleteIcon color="error" />}
            </Button>
        );

    const isLocked = EnumHelper.isEqual(
        row.state,
        WorkShiftTimeSlotState.Locked
    );

    const toggleShowCalculated =
        rowNode.type === "group"
            ? {
                label: rowNode.childrenExpanded
                    ? t("workTime.hideCalculatedWorkHours")
                    : t("workTime.showCalculatedWorkHours"),
                icon: rowNode.childrenExpanded ? (
                    <ExpandLessIcon color="primary" />
                ) : (
                    <ExpandMoreIcon color="primary" />
                ),
                onClick: () =>
                    apiRef.current.setRowChildrenExpansion(
                        row.id,
                        !rowNode.childrenExpanded
                    ),
            }
            : null;

    const items = isLocked
        ? [
            {
                label: Translations.DoUnlockAll,
                icon: <LockOpenIcon color="primary" />,
                onClick: () => handleLock([value], false),
            },
            toggleShowCalculated,
        ]
        : [
            {
                label: Translations.Edit,
                icon: <EditIcon color="primary" />,
                onClick: () => handleEdit(value),
            },
            {
                label: Translations.DoLockAll,
                icon: <LockIcon color="primary" />,
                onClick: () => handleLock([value], true),
            },
            toggleShowCalculated,
            { divider: true },
            {
                label: Translations.Remove,
                icon: <DeleteIcon color="error" />,
                onClick: () => handleDelete(value),
            },
        ];

    return (
        <MuiMenu
            label={
                isLocked ? (
                    <LockIcon
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                ) : (
                    <MoreHorizIcon
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )
            }
            items={items}
        />
    );
}

export function WorkTimeDetailsList({
    reloadList,
    workShiftTimeSlotItems,
    salaryRowTypes,
}: IWorkTimeDetailsListProps) {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const apiRef = useGridApiRef();

    const shownColumns = useAppSelector((state) => state.workTimeDetails.workTimeDetailsListShownColumns);
    const [
        overlappingWorkShiftTimeSlotItemIds,
        setOverlappingWorkShiftTimeSlotItemIds,
    ] = useState<string[]>();
    const { date, employeeId } = useParams<IWorkTimeDetailsPage>();
    const allLocked =
        workShiftTimeSlotItems.length > 0 &&
        workShiftTimeSlotItems.every((i) =>
            new WorkShiftTimeSlotItem(i).isLocked()
        );

    const [selectedLocation, setSelectedLocation] =
        useState<ISelectedLocation>();
    const { t } = useTranslation();
    const selectedId = store.useAppSelector(
        (state) => state.workShiftTimeSlot.selectedId
    );

    const workHours = useSelectCalculatedWorkHoursByDate(
        employeeId,
        date,
        date
    );

    const rows = useMemo(
        () => [
            ...workHours
                .map((s) => {
                    const slot = workShiftTimeSlotItems.find(
                        (i) => i.id === s.workShiftTimeSlotId
                    );

                    return {
                        costCenterName:
                            s.costCenterId === slot?.costCenterId
                                ? slot?.costCenterName
                                : null,
                        ...s,
                        id: slot
                            // To avoid duplicate ids:
                            // In case there is slot spanning multiple days,
                            // there could be many same category additions for same slot with different timestamps.
                            ? `${slot.id}.${s.categoryId}_${s.startTime}`
                            : null,
                    };
                })
                .filter((s) => s.id),
            ...workShiftTimeSlotItems.flatMap((i) => [
                i,
                ...i.ignoredCategories.map((c) => ({
                    id: `${i.id}.deleted-${c}`,
                    workShiftTimeSlotId: i.id,
                    category: c.toString(),
                    deleted: true,
                    categoryName:
                        Translations[
                            `Category${Object.keys(HourBookingCategory).find(
                                (k) => HourBookingCategory[k] === c
                            )}`
                        ],
                })),
            ]),
        ],
        [workShiftTimeSlotItems, workHours]
    );

    useEffect(() => {
        setOverlappingWorkShiftTimeSlotItemIds(
            workShiftTimeSlotItems
                .filter(
                    (item) =>
                        !item.isCustomType &&
                        workShiftTimeSlotItems.some(
                            (item2) =>
                                !item2.isCustomType &&
                                Base.isOverlappingDate(
                                    item.startDate,
                                    item.endDate,
                                    item2.startDate,
                                    item2.endDate
                                ) &&
                                item.id !== item2.id
                        )
                )
                .map((item) => item.id)
        );
    }, [workShiftTimeSlotItems.map((i) => i.rowId).join(",")]);

    const handleLocationClick = useCallback(
        (locationName: string, latitude: number, longitude: number) => {
            setSelectedLocation({
                title: locationName || Translations.Location,
                point: LocationPoint.createLocationPoint(
                    Base.getGuid(),
                    latitude,
                    longitude,
                    0,
                    locationName
                ),
            });
        },
        []
    );

    const handleDelete = useCallback((id: string) => {
        if (!id) return;
        store.customStore.dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                Translations.WorkShiftTimeSlotRemoveConfirmation,
                () => {
                    store.customStore.dispatch(
                        storeActions.clearConfirmation()
                    );
                    setLoading(true);

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    store.customStore
                        .dispatch(
                            deleteWorkShiftTimeSlot(id) as unknown as AnyAction
                        )
                        .unwrap()
                        .then(() => {
                            store.customStore.dispatch(
                                storeActions.showSuccessMessage(
                                    t("workTimeDeleteSuccessful")
                                )
                            );
                        })
                        .catch((error) => {
                            store.customStore.dispatch(
                                storeActions.showErrorMessage(error.message)
                            );
                        })
                        .finally(() => {
                            reloadList();
                            setLoading(false);
                        });
                },
                () => {
                    store.customStore.dispatch(
                        storeActions.clearConfirmation()
                    );
                }
            )
        );
    }, []);

    const handleToggleDeleteWorkHour = useCallback(
        (
            id: string,
            category: string,
            categoryName: string,
            restore: boolean
        ) => {
            if (!id || !category) return;
            store.customStore.dispatch(
                storeActions.setConfirmation(
                    ConfirmationDialogType.Warning,
                    Translations.Warning,
                    t(
                        restore
                            ? "workTime.workHourRestoreConfirmation"
                            : "workTime.workHourRemoveConfirmation",
                        {
                            category: categoryName,
                        }
                    ),
                    () => {
                        store.customStore.dispatch(
                            storeActions.clearConfirmation()
                        );
                        setLoading(true);

                        workTimeBetaService
                            .deleteCategoryFromWorkShiftTimeSlot(
                                id,
                                category,
                                restore
                            )
                            .then(() => {
                                store.customStore.dispatch(
                                    storeActions.showSuccessMessage(
                                        t(
                                            restore
                                                ? "workTime.workHourRestoreSuccesful"
                                                : "workTime.workHourDeleteSuccesful",
                                            {
                                                category: categoryName,
                                            }
                                        )
                                    )
                                );
                            })
                            .catch((error) => {
                                store.customStore.dispatch(
                                    storeActions.showErrorMessage(error.message)
                                );
                            })
                            .finally(() => {
                                setLoading(false);
                                reloadList();
                            });
                    },
                    () => {
                        store.customStore.dispatch(
                            storeActions.clearConfirmation()
                        );
                    }
                )
            );
        },
        []
    );

    const handleEdit = useCallback(
        (id: string) => {
            store.customStore.dispatch(setEditId(id));
        },
        [store.customStore.dispatch]
    );

    const handleLock = useCallback((ids: string[], lock: boolean) => {
        if (ids.length === 0) return;
        setLoading(true);
        (lock
            ? workTimeBetaService.lockWorkTimes(ids)
            : workTimeBetaService.unlockWorkTimes(ids)
        )
            .then((success) => {
                store.customStore.dispatch(
                    storeActions.showSuccessMessage(success.message)
                );
                reloadList();
            })
            .catch((error) => {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(
                        baseService.getErrorMessageFromError(error)
                    )
                );
                return null;
            })
            .finally(() => setLoading(false));
    }, []);

    const cols: GridColDef<
        WorkShiftTimeSlotItem & IWorkHoursItem & { deleted?: boolean }
    >[] = useMemo(
        () => [
            {
                field: "automatic",
                headerName: "",
                width: 60,
                renderCell: (params) =>
                    params.rowNode.depth > 0 ? null : (
                        <WorkTimeAutomationsIndicator slot={params.row} />
                    ),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
            },
            {
                field: "startLocationName",
                headerName: Translations.StartLocation,
                width: locationIconColWidth,
                renderCell: (
                    params: GridRenderCellParams<WorkShiftTimeSlotItem>
                ) => (
                    <LocationIcon
                        title={params.row.startLocationName}
                        latitude={params.row.startLatitude}
                        longitude={params.row.startLongitude}
                        handleClick={handleLocationClick}
                    />
                ),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
            },
            {
                field: "startTime",
                headerName: Translations.WorkTime,
                width: startTimeColWidth,
                valueGetter: (params): StartTimeColValue => {
                    return params.rowNode.depth > 0
                        ? null
                        : {
                            startDate: params.row.startDate,
                            endDate: params.row.endDate,
                            hasOverlapping:
                                  overlappingWorkShiftTimeSlotItemIds?.includes(
                                      params.row.id
                                  ),
                            label: `${formatTime(
                                  params.row.startDate,
                                  date
                              )} - ${
                                  params.row.state !==
                                  WorkShiftTimeSlotState.InProgress
                                      ? formatTime(params.row.endDate, date)
                                      : ""
                              } ${Base.dateTzOffsetIfNotLocal(
                                  params.row.startDate,
                                  params.row.timeZoneName
                              )}`,
                        };
                },
                valueFormatter: (
                    params: GridValueFormatterParams<StartTimeColValue>
                ) => params.value?.label,
                sortComparator: (
                    a: WorkShiftTimeSlotItem,
                    b: WorkShiftTimeSlotItem
                ) => Base.dayjsCompare(a?.startDate, b?.startDate),
                renderCell: (
                    params: GridRenderCellParams<WorkShiftTimeSlotItem>
                ) =>
                    params.value ? (
                        <Tooltip
                            title={`${
                                (params.value as StartTimeColValue)?.label
                            } ${params.row.timeZoneDisplayName}`}
                        >
                            <span
                                className={`text-truncate ${
                                    params.value?.hasOverlapping
                                        ? "text-danger"
                                        : ""
                                }`}
                            >
                                {params.value?.label}
                            </span>
                        </Tooltip>
                    ) : null,
            },
            {
                field: "endLocationName",
                headerName: Translations.EndLocation,
                width: locationIconColWidth,
                renderCell: (
                    params: GridRenderCellParams<WorkShiftTimeSlotItem>
                ) => (
                    <LocationIcon
                        title={params.row.endLocationName}
                        latitude={params.row.endLatitude}
                        longitude={params.row.endLongitude}
                        handleClick={handleLocationClick}
                    />
                ),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
            },
            {
                field: "timeZoneDisplayName",
                headerName: Translations.TimeZone,
                flex: 1,
            },
            {
                field: "endTime",
                headerName: `${Translations.Hours}`,
                flex: 1,
                valueGetter: (params) => {
                    const srt = salaryRowTypes.find(
                        (srt) => srt.id === params.row.salaryRowTypeId
                    );

                    return params.rowNode.depth > 0
                        ? params.row.amount
                            ? {
                                time: srt
                                    ? AppUtils.getDisplayedNumberBasedOnUnit(
                                        params.row.amount,
                                        srt.measureUnit,
                                        srt.decimals
                                    )
                                    : AppUtils.getDurationStrByDurationMinShort(
                                        params.row.amount
                                    ),
                                edited: false,
                            }
                            : null
                        : {
                            time: new WorkShiftTimeSlotItem(
                                params.row
                            ).getDurationStr(),
                            edited: params.row.edited,
                        };
                },
                renderCell: (
                    params: GridRenderCellParams<WorkShiftTimeSlotItem>
                ) => (
                    <>
                        <WorkTimeEventIndicator
                            id={params.row.id}
                            baseLabel={params.value?.time}
                            edited={params.row.edited}
                            orgStartTime={params.row.orgStartDate}
                            orgEndTime={params.row.orgEndDate}
                        />
                    </>
                ),
            },
            {
                field: "workOrderName",
                headerName: Translations.CostCenter,
                flex: 1,
                valueGetter: (params) =>
                    params.row.costCenterName ||
                    (params.row.workOrderName
                        ? `${params.row.workOrderNumber} ${params.row.workOrderName}`
                        : ""),
            },
            {
                field: "customerName",
                headerName: Translations.Customer,
                flex: 1,
            },
            {
                field: "workTimeTypeName",
                headerName: Translations.WorkTimeType,
                flex: 1,
                valueGetter: (params) =>
                    params.rowNode.depth > 0
                        ? params.row.categoryName
                        : new WorkShiftTimeSlotItem(
                            params.row
                        ).getWorkTimeTypeName(),
                renderCell: (params) =>
                    params.row.deleted ? (
                        <Tooltip title={t("common.removed")}>
                            <span className="text-line-through">
                                {params.value}
                            </span>
                        </Tooltip>
                    ) : (
                        <div
                            title={params.value}
                            className="MuiDataGrid-cellContent"
                        >
                            {params.value}
                        </div>
                    ),
            },
            {
                field: "salaryRowTypeName",
                headerName: Translations.SalaryRowType,
                flex: 1,
            },
            {
                field: "comment",
                headerName: Translations.Description,
                flex: 2,
            },
            {
                field: "vehicleRegisterNumber",
                headerName: Translations.Vehicle,
                flex: 1,
                valueGetter: (params) =>
                    `${params.row.vehicleRegisterNumber || ""}${
                        params.row.vehicleBrand
                            ? `, ${params.row.vehicleBrand}`
                            : ""
                    }`,
            },
            {
                field: "id",
                headerName: "",
                width: 75,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                hideable: false,
                renderCell: (params) => (
                    <ActionCell
                        {...params}
                        handleLock={handleLock}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleToggleDeleteWorkHour={handleToggleDeleteWorkHour}
                    />
                ),
            },
        ],
        [
            handleEdit,
            handleLock,
            handleLocationClick,
            handleDelete,
            handleToggleDeleteWorkHour,
            date,
            overlappingWorkShiftTimeSlotItemIds?.join(","),
            salaryRowTypes,
        ]
    );

    const onRowSelect = (id: string | undefined) => {
        if (id?.includes(".")) return; // Skip children
        store.customStore.dispatch(toggleSelectedId(id));
    };

    const groupingColDef: DataGridPremiumProps["groupingColDef"] = {
        renderCell: () => null,
    };

    useEffect(() => {
        let unsubscribeRowMouseEnter: (() => void) | undefined;
        let unsubscribeRowMouseLeave: (() => void) | undefined;

        if (apiRef.current?.subscribeEvent) {
            unsubscribeRowMouseEnter = apiRef.current.subscribeEvent("rowMouseEnter", (params) =>
                dispatch(
                    setHoveredId(params.id as string)
                )
            );
            unsubscribeRowMouseLeave = apiRef.current.subscribeEvent("rowMouseLeave", () =>
                dispatch(
                    setHoveredId(null)
                )
            );
        }

        return () => { // returns a cleaning callback that unsubscribes the given handler when called (prevent multiple registrations)
            if (unsubscribeRowMouseEnter) unsubscribeRowMouseEnter();
            if (unsubscribeRowMouseLeave) unsubscribeRowMouseLeave();
        };
    }, [apiRef, dispatch]);

    useEffect(() => {
        ReactDOM.flushSync(() => {
            apiRef.current.autosizeColumns({
                columns: ['startTime'],
                includeOutliers: true,
                includeHeaders: true,
              })
          });
      }, [apiRef, rows])

    if (workShiftTimeSlotItems.length === 0 || !(salaryRowTypes?.length > 0)) {
        return null;
    }

    return (
        <>
            <LoadingIndicator loading={loading} />
            <MuiDataGrid
                apiRef={apiRef}
                density="compact"
                treeData
                getTreeDataPath={(row) => row.id.split(".")}
                disableChildrenSorting
                disableChildrenFiltering
                groupingColDef={groupingColDef}
                onRowSelectionModelChange={(newRowSelectionModel) =>
                    onRowSelect(newRowSelectionModel[0] as string)
                }
                rowSelectionModel={[selectedId].filter(Boolean)}
                columnVisibilityModel={shownColumns}
                onColumnVisibilityModelChange={(newModel) => {
                    dispatch(setShownColumns(newModel));
                }}
                title={Translations.WorkingTimeRecords}
                autoHeight
                rows={rows}
                columns={cols}
                autosizeOnMount={true}
                autosizeOptions={{
                    columns: ['startTime'],
                    includeOutliers: true,
                    includeHeaders: true,
                }}
                hideFooter
                initialState={{
                    sorting: {
                        sortModel: [
                            {
                                field: "startTime",
                                sort: "asc",
                            },
                        ],
                    },
                }}
                customToolbarButtons={[
                    {
                        key: "lock",
                        startIcon: allLocked ? <LockOpenIcon /> : <LockIcon />,
                        onClick: () =>
                            handleLock(
                                workShiftTimeSlotItems.map((i) => i.id),
                                !allLocked
                            ),
                        children: allLocked
                            ? Translations.UnlockAll
                            : Translations.LockAll,
                    },
                ]}
            />
            {selectedLocation ? (
                <LocationMapDialog
                    title={selectedLocation.title}
                    points={[selectedLocation.point]}
                    selectedIds={[selectedLocation.point?.id]}
                    onClose={() => setSelectedLocation(null)}
                />
            ) : null}
        </>
    );
}
