import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Map, MapPointProps } from "../framework/map/map";
import LocalShippingIcon from "@mui/icons-material/LocalShippingOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    getWorkTimeTypeColorClass,
    getWorkTimeTypeContrastColorClass,
} from "../../models/workShitTimeSlot/workTimeType";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { SectionHeader } from "../layout/sectionHeader";
import { useTranslation } from "react-i18next";
import MuiMenu from "../framework/muiMenu";
import { MuiSwitch } from "../framework/muiSwitch";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { setGroupMapPoints } from "../../store/monitorViewSlice";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { useWorkShiftTimeSlotMapData } from "../../hooks/workTime/useWorkShiftTimeSlotMapData";
import { useReload } from "../../hooks";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";

interface MonitorViewMapProps {
    loading: boolean;
    positions: VehiclePositionInfo[];
    onRefresh: () => void;
    selectedVehicleId: string;
    setSelectedVehicle: (id: string) => void;
    workShift: WorkShiftDto;
    reloadKey: number;
    selectedWorkShiftTimeSlotId: string;
    hoveredWorkShiftTimeSlotId: string;
    setSelectedWorkShiftTimeSlotId: (id: string) => void;
}

export const MonitorViewMap = ({
    loading,
    positions,
    onRefresh,
    selectedVehicleId,
    setSelectedVehicle,
    workShift,
    reloadKey,
    selectedWorkShiftTimeSlotId,
    hoveredWorkShiftTimeSlotId,
    setSelectedWorkShiftTimeSlotId,
}: MonitorViewMapProps) => {
    const [workShiftPositionsLoading, setWorkShiftPositionsLoading] =
        useState(false);
    const { t } = useTranslation();
    const groupPoints = useAppSelector(
        (state) => state.monitorView.groupMapPoints
    );
    const showNoWorkTime = useAppSelector(
        (state) => state.monitorView.filters.noWorkTime
    );

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const dispatch = useAppDispatch();
    const [zoomKey, zoom] = useReload();
    const shouldZoom = useRef(false);

    const { routes, points, reload } = useWorkShiftTimeSlotMapData(
        workShift?.workShiftTimeSlots ?? [],
        selectedWorkShiftTimeSlotId,
        hoveredWorkShiftTimeSlotId
    );

    useEffect(() => {
        reload();
    }, [reloadKey]);

    useEffect(() => {
        if (!!workShift?.workShiftId) {
            setWorkShiftPositionsLoading(true);
            shouldZoom.current = true;
        }
    }, [workShift?.workShiftId]);

    useEffect(() => {
        // Zoom when workShift has changed and routes and points have been loaded
        if (shouldZoom.current && routes?.length > 0 && points?.length > 0) {
            setWorkShiftPositionsLoading(false);
            zoom();
            shouldZoom.current = false;
        }
    }, [routes, points]);

    useEffect(() => {
        // Zoom when filters have changed
        if (!!selectedWorkTimeTypeIds) {
            zoom();
        }
    }, [selectedWorkTimeTypeIds, showNoWorkTime]);

    const filterByWorkTimeType = useCallback(
        (p: VehiclePositionInfo) =>
            !selectedWorkTimeTypeIds ||
            p.employeeWorkTimeTypes.some((w) =>
                selectedWorkTimeTypeIds.includes(w.workTimeTypeId)
            ),
        [selectedWorkTimeTypeIds]
    );

    const mapPoints = useMemo(
        () =>
            positions
                ?.filter(
                    (d) =>
                        // Filter by selected vehicle, if present
                        (!selectedVehicleId ||
                            selectedVehicleId === d.vehicleId) &&
                        // And if vehicles with no workTime are not shown,
                        // Only filter by WorkTimeType
                        ((!showNoWorkTime && filterByWorkTimeType(d)) ||
                            // Or if vehicles with no workTime are shown
                            (showNoWorkTime &&
                                // find vehicles with no workTimes
                                d.employeeWorkTimeTypes.length === 0) ||
                            // Or by WorkTimeType
                            filterByWorkTimeType(d))
                )
                .map(
                    (d) =>
                        ({
                            id: d.vehicleId,
                            coords: { lat: d.latitude, lng: d.longitude },
                            icon: (
                                <LocalShippingIcon
                                    className={`text-${getWorkTimeTypeContrastColorClass(
                                        d.employeeWorkTimeTypes[0]
                                            ?.workTimeTypeType
                                    )}`}
                                />
                            ),
                            text: d.registerNumber,
                            fullText: [
                                d.registerNumber,
                                d.employeeWorkTimeTypes[0]?.employeeName,
                                d.employeeWorkTimeTypes[0]?.workTimeTypeName,
                            ]
                                .filter(Boolean)
                                .join(" - "),
                            textColorClass: getWorkTimeTypeContrastColorClass(
                                d.employeeWorkTimeTypes[0]?.workTimeTypeType
                            ),
                            textBgColorClass: getWorkTimeTypeColorClass(
                                d.employeeWorkTimeTypes[0]?.workTimeTypeType
                            ),
                        } as MapPointProps)
                ),
        [positions, showNoWorkTime, selectedVehicleId, filterByWorkTimeType]
    );

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <LoadingIndicator loading={workShiftPositionsLoading} />
            <div>
                <SectionHeader>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        className="section-header text-truncate"
                    >
                        {t("monitor")}
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => zoom()}
                        title={t("map.centerFocus")}
                    >
                        <CenterFocusStrongIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        onClick={onRefresh}
                        disabled={loading}
                    >
                        <RefreshIcon />
                    </IconButton>
                    <MuiMenu
                        label={<SettingsIcon color="white" />}
                        keepOpenAfterSelect
                        items={[
                            {
                                label: (
                                    <MuiSwitch
                                        label={t("map.groupIcons")}
                                        checked={groupPoints}
                                        readOnly
                                        onChange={(_, checked) =>
                                            dispatch(setGroupMapPoints(checked))
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </SectionHeader>
            </div>
            {!!mapPoints ? (
                <Map
                    points={!!workShift ? points : mapPoints}
                    routes={!!workShift ? routes : []}
                    autoZoom={false}
                    zoomKey={zoomKey}
                    groupPoints={groupPoints}
                    pointSelectionEnabled
                    onPointSelect={
                        !!workShift
                            ? setSelectedWorkShiftTimeSlotId
                            : setSelectedVehicle
                    }
                    hoverMenuEnabled
                />
            ) : null}
        </Box>
    );
};
