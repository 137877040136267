﻿import React, { useMemo } from "react";
import { Map } from "../framework/map/map";
import { Base, maxBy, minBy } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { SectionHeader } from "../layout/sectionHeader";
import { useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../framework/customStore";
import { useWorkShiftTimeSlotMapData } from "../../hooks/workTime/useWorkShiftTimeSlotMapData";

export const WorkTimeDetailsMap = ({
    workShiftTimeSlots: timeSlots,
}: {
    workShiftTimeSlots: WorkShiftTimeSlotItem[];
}) => {
    const { date } = useParams<IWorkTimeDetailsPage>();
    const selectedId = useAppSelector(
        (state) => state.workShiftTimeSlot.selectedId
    );
    const hoveredId = useAppSelector(
        (state) => state.workTimeDetails.hoveredId
    );

    const workShiftTimeSlots = useMemo(
        () => timeSlots.filter((s) => !s.isCustomType),
        [timeSlots]
    );

    const { routes, points } = useWorkShiftTimeSlotMapData(
        workShiftTimeSlots,
        selectedId,
        hoveredId
    );

    const items = useMemo(
        () =>
            workShiftTimeSlots.map((t) => ({
                ...t,
                endDate:
                    t.endDate ||
                    Base.dayjsToJsonDateTimeOffset(
                        Base.dateAtTz(t.timeZoneName)
                    ),
            })),
        [workShiftTimeSlots.map((s) => s.rowId).join(",")]
    );

    const headerLabel = useMemo(() => {
        const start = minBy(items, (t) =>
            Base.dateStrToDayjsIgnoreTimezone(t.startDate).valueOf()
        )?.startDate;
        const end = maxBy(items, (t) =>
            Base.dateStrToDayjsIgnoreTimezone(t.endDate).valueOf()
        )?.endDate;
        return [
            items[0]?.employeeName,
            Base.dayjsToDateStr(date),
            start && end
                ? `${Base.dateStrToOriginalTimezoneTimeStr(
                      start
                  )}-${Base.dateStrToOriginalTimezoneTimeStr(end)}`
                : "",
        ].join(" ");
    }, [items, date]);

    if (routes?.length === 0 && points?.length === 0)
        return null;

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <div>
                <SectionHeader>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        className="section-header text-truncate"
                    >
                        {headerLabel}
                    </Typography>
                </SectionHeader>
            </div>
            <Map routes={routes} points={points} minHeight="300px" />
        </Box>
    );
};
